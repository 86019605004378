import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

export default function Example() {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Fragment>
      <Accordion open={open === 1} className="w-11/12 md:w-3/5">
        <AccordionHeader
          onClick={() => handleOpen(1)}
          className="font-lato font-normal text-cyan-700"
        >
          Can I arrange an appointment outside of the working hours?
        </AccordionHeader>
        <AccordionBody className="font-roboto font-light">
          Yes! Appointments can be arrangement outside of working hours. Please
          call over the phone or drop an email and we will be in touch
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 2} className="w-11/12 md:w-3/5">
        <AccordionHeader
          onClick={() => handleOpen(2)}
          className="font-lato font-normal text-cyan-700"
        >
          Do I need an initial appointment?
        </AccordionHeader>
        <AccordionBody className="font-roboto font-light">
          During an initial appointment the foot healthcare practitioner can
          assess your feet and discuss the best option for you. However for
          routine appointments for calluses, blisters, nail cut and bur, this
          not necessary
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 3} className="w-11/12 md:w-3/5">
        <AccordionHeader
          onClick={() => handleOpen(3)}
          className="font-lato font-normal text-cyan-700"
        >
          Do I need to arrive early to the appointment?
        </AccordionHeader>
        <AccordionBody className="font-roboto font-light">
          We ask that you arrive 5 minutes early so you can fill out a patient
          intake form.
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 4} className="w-11/12 md:w-3/5">
        <AccordionHeader
          onClick={() => handleOpen(4)}
          className="font-lato font-normal text-cyan-700"
        >
          Where can I find Millrace Foot Care?
        </AccordionHeader>
        <AccordionBody className="font-roboto font-light">
          We are located inside Battersea Park Clinic, Just ring the bell for
          one of our staff to let you in.
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 5} className="w-11/12 md:w-3/5">
        <AccordionHeader
          onClick={() => handleOpen(5)}
          className="font-lato font-normal text-cyan-700"
        >
          I can't make my appointment, what do I do?
        </AccordionHeader>
        <AccordionBody className="flex flex-row flex-wrap gap-2 font-roboto font-light">
          <span className="w-full">
            No problem, just let us know with 24 hours and we can reschedule for
            you.
          </span>
          <span className="w-full text-xs italic">
            *appointment cancellations made less than 24H will be charged at £25
          </span>
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 6} className="w-11/12 md:w-3/5">
        <AccordionHeader
          onClick={() => handleOpen(6)}
          className="font-lato font-normal text-cyan-700"
        >
          Are used tools cleaned and sterilised?
        </AccordionHeader>
        <AccordionBody className="font-roboto font-light">
          Your safety is our top priority, all tools are thoroughly sterilized
          for your peace of mind.
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 7} className="w-11/12 md:w-3/5">
        <AccordionHeader
          onClick={() => handleOpen(7)}
          className="font-lato font-normal text-cyan-700"
        >
          My ingrown toenail is causing me a lot of pain. Can you give me an
          anesthetic?
        </AccordionHeader>
        <AccordionBody className="font-roboto font-light">
          Unfortunately not, FHPs in the UK an not able to administer local
          anesthetic.
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 8} className="w-11/12 md:w-3/5">
        <AccordionHeader
          onClick={() => handleOpen(8)}
          className="font-lato font-normal text-cyan-700"
        >
          How do I book an appointment?
        </AccordionHeader>
        <AccordionBody className="font-roboto font-light">
          Over the phone or drop us an email, there is a £20 deposit, which will
          be taken off the price of your appointment
        </AccordionBody>
      </Accordion>
    </Fragment>
  );
}
