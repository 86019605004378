import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Index from "./views/index.js";
import Contact from "./views/contact.js";
import FAQ from "./views/faq.js";
import About from "./views/about.js";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDaOYigxX3Uy5-SRmZlqfXSbTpJe5iF5vs",
  authDomain: "millrace-footcare.firebaseapp.com",
  projectId: "millrace-footcare",
  storageBucket: "millrace-footcare.appspot.com",
  messagingSenderId: "128669497133",
  appId: "1:128669497133:web:7348090dcaa507f06505a4",
  measurementId: "G-82BQT8Y8F9",
};

// Initialize Firebase

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <>
      <Routes>
        <Route index element={<Index />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/*" element={<Index />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}

export default App;
