import React from "react";
import { Helmet } from "react-helmet";

import HeroImage from "../components/HeroImage.js";
import Footer from "../components/Footer.js";
import Accordion from "../components/Accordion.js";

const Content = () => {
  return (
    <h1 className="w-full text-center font-lato font-medium text-white text-6xl">
      About Our Services
    </h1>
  );
};

export default function FAQ() {
  return (
    <>
      <Helmet>
        <title>
          Battersea Foot Care Specialist: Expert Treatment for Foot Pain in
          South London - Frequently Asked Questions
        </title>
        <meta
          name="description"
          content="Find answers to common questions about our foot care services on our FAQ page. Learn more about our treatments and appointment process. Contact us for any additional inquiries."
        />
      </Helmet>
      <div>
        <main className="justify-center items-center content-center w-full">
          <HeroImage background="bg-hero" content={<Content />} />
          <div className="w-full justify-center items-center content-center flex flex-row flex-wrap mt-10 gap-4">
            <div className="w-3/4 justify-start">
              <h1 className="font-lato font-medium text-2xl text-cyan-700">
                Fissures and Cracked Heels
              </h1>
              <p className="font-roboto mt-2">
                Discover the key to flawless, healthy feet with Millrace
                Footcare! Say goodbye to the discomfort and potential medical
                complications of heel fissures, also known as cracked heels.
                This seemingly simple issue can escalate into a serious concern
                if left untreated.
              </p>
              <p className="font-roboto mt-2">
                Experience the transformative power of our personalized
                aftercare solutions designed to restore your feet to their
                optimal state. Don't ignore the warning signs! Look out for
                thickened, discolored skin, visible cracks, and bleeding. Left
                untreated, mild symptoms can escalate to open wounds, unbearable
                pain while walking or applying pressure, and even deep ulcers
                prone to infections like cellulitis.
              </p>
              <p className="font-roboto mt-2">
                Take charge of your foot health and prevent these complications
                with our tailored treatments. Regain your confidence and enjoy
                the pleasure of pain-free, beautiful feet. Let us guide you on
                your journey to healthy, happy feet. Contact us today to
                experience the Millrace Footcare difference!
              </p>
            </div>
            <div className="w-3/4 justify-start">
              <h1 className="font-lato font-medium text-2xl text-cyan-700">
                Verrucae
              </h1>
              <p className="font-roboto mt-2">
                Are verrucas causing you discomfort and frustration? These small
                lumps on the skin, caused by the Human Papilloma Virus (HPV),
                can easily spread through skin contact and touching infected
                surfaces. But worry not, we have the solutions you need.
              </p>
              <p className="font-roboto mt-2">
                Verrutop, our professional-use topical solution, offers a
                painless treatment for difficult warts. With just a few
                applications, it delivers highly effective results, helping you
                bid farewell to those stubborn warts.
              </p>
              <p className="font-roboto mt-2">
                If you prefer another option, we also offer cryotherapy. Our
                skilled professionals start by gently scraping away dead skin
                cells surrounding the wart using a scalpel. This prepares the
                area for treatment. Liquid nitrogen, the freezing agent, is then
                applied, typically for a duration of 10-20 seconds. This
                targeted freezing helps eliminate the wart and the underlying
                virus.
              </p>
              <p className="font-roboto mt-2">
                <p className="font-roboto mt-2">
                  If you prefer another option, we also offer cryotherapy. Our
                  skilled professionals start by gently scraping away dead skin
                  cells surrounding the wart using a scalpel. This prepares the
                  area for treatment. Liquid nitrogen, the freezing agent, is
                  then applied, typically for a duration of 10-20 seconds. This
                  targeted freezing helps eliminate the wart and the underlying
                  virus.
                </p>
              </p>
            </div>
            <div className="w-3/4 justify-start">
              <h1 className="font-lato font-medium text-2xl text-cyan-700">
                Paraffin Wax Bath Benefits
              </h1>
              <p className="font-roboto mt-2">
                Experience the soothing wonders of a paraffin wax bath treatment
                and indulge in a world of relaxation and rejuvenation. This
                luxurious treatment offers a multitude of benefits that go
                beyond pampering your feet. Discover the potential advantages
                that await you:
              </p>
              <ul className="font-roboto mt-2 ml-6 list-decimal">
                <li className="mt-1">
                  Increased Blood Flow: Immerse your feet in the warm embrace of
                  paraffin wax and feel the gentle stimulation of local blood
                  circulation. This enhanced blood flow can promote healing,
                  nourish tissues, and improve overall foot health.
                </li>
                <li className="mt-1">
                  Muscle and Joint Relaxation: Unwind as the paraffin wax
                  envelops your feet, easing muscle tension and joint stiffness.
                  Let the treatment melt away stress and provide a calming
                  effect on your tired feet.
                </li>
                <li className="mt-1">
                  Alleviation of Aches and Pains: Find relief from minor aches
                  and pains through the therapeutic properties of paraffin wax.
                  As the wax gently soothes your feet, you may experience a
                  reduction in discomfort and a renewed sense of well-being.
                </li>
                <li className="mt-1">
                  Skin Rejuvenation: Revel in the softness and suppleness that
                  follows a paraffin wax foot treatment. The wax helps to
                  moisturize and nourish your skin, leaving it feeling
                  revitalized, smooth, and beautifully hydrated.
                </li>
                <li className="mt-1">
                  Softening of Adhesions and Scars: Paraffin wax has the
                  potential to soften adhesions and scars, contributing to the
                  healing process and improving the appearance of calluses and
                  scar tissue.
                </li>
              </ul>
            </div>
            <div className="w-3/4 justify-start">
              <h1 className="font-lato font-medium text-2xl text-cyan-700">
                Involuted toenails and the non-surgical approach
              </h1>
              <p className="font-roboto mt-2">
                An involuted nail can be quite bothersome, with its pronounced
                curve that digs sharply into the groove of your toe. This
                pressure can cause pain and even lead to the formation of
                calluses or corns if left untreated. You may also notice redness
                around the affected nail. With nail bracing, you can enjoy a
                simple, effective, and non-invasive treatment option.
              </p>
              <p className="font-roboto mt-2">
                Similar to the concept of orthodontic braces for teeth, nail
                bracing helps restore the natural shape of your nails. This
                innovative technique reduces the likelihood of ingrown nails and
                alleviates pain caused by nails that curl inwards.
              </p>
              <p className="font-roboto mt-2">
                The best part? Nail bracing requires no needles, no anaesthesia,
                and no extended periods of rest. You can quickly resume your
                daily activities without any interruption. Experience the
                freedom from painful nails and the joy of having nails that look
                and feel healthy again.
              </p>
              <p className="font-roboto mt-2">
                <p className="font-roboto mt-2">
                  Contact us today to explore the benefits of nail bracing and
                  start your journey towards pain-free and beautifully-shaped
                  nails. Embrace the change with Nail Bracing!
                </p>
              </p>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}
