import BookButton from "../components/BookButton";
import MobileNavBar from "../components/MobileNavBar.js";
import NavBar from "../components/NavBar.js";

export default function HeroImage(props) {
  return (
    <div
      className={`${props.background} bg-cover bg-center mt-0`}
      alt="Background Hero Image"
    >
      <NavBar />
      <header className="w-full h-96  flex md:justify-center md:items-center pointer-events-none md:pointer-events-auto">
        {props.content}
      </header>
    </div>
  );
}
