import React from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/NavBar.js";
import HeroImage from "../components/HeroImage.js";
import ServiceCard from "../components/ServiceCard.js";
import Ratings from "../components/Ratings.js";
import ContactForm from "../components/ContactForm.js";
import Footer from "../components/Footer.js";
import { LogBooking } from "../analytics";

//#region Images
import NewAppointment from "../assets/images/newAppointment.jpg";
import Cryotheraphy from "../assets/images/cryotherapy.jpg";
import FootWrap from "../assets/images/footWrap.jpg";
import LowerLimb from "../assets/images/lowerLimb.jpg";
import Neuropathy from "../assets/images/neuropathy.jpg";
import Orthotic from "../assets/images/orthotic.jpg";
import ReturningAppointment from "../assets/images/returningAppointment.jpg";
import Toenail from "../assets/images/toenail.png";
import ThaiMassage from "../assets/images/thaiMassage.jpg";
//#endregion

const Content = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <p className="mt-5 text-lg text-white md:w-1/2 font-roboto w-11/12">
        At Millrace foot-care we provide essential services relating to the
        feet. We keep your feet healthy and in working order. We pride ourselves
        on supplying the public with a professional and confidential service.
        Conveniently located in South London, Battersea. Just a short walk from
        Clapham Junction station.
      </p>

      <button onClick={() => LogBooking("generic")} className="mt-5">
        <a
          className="mt-8 px-12 py-3 bg-gradient-to-r from-cyan-700 to-teal-700 hover:from-cyan-800 hover:to-teal-800 text-xl text-white font-lato font-medium drop-shadow-lg rounded-full pointer-events-auto"
          href="https://millracefootcare.simplybook.it/v2/#book/count/1/"
          target="_blank"
        >
          Book Now
        </a>
      </button>
    </div>
  );
};

export default function Index() {
  return (
    <>
      <Helmet>
        <title>
          Battersea Foot Care Specialist: Expert Treatment for Foot Pain in
          South London
        </title>
        <meta
          name="description"
          content="Discover expert foot care in Battersea, London with our experienced foot specialist. Our dedicated team offers top-quality treatments for a variety of foot conditions, from routine check-ups to complex conditions. Trust us to alleviate your foot pain and improve your overall foot health. Book your appointment today."
        />
      </Helmet>
      <div className="bg-gradient-to-r from-teal-600 to-teal-300">
        <main className="justify-center items-center content-center bg-white">
          <HeroImage background="bg-hero" content={<Content />} />

          <div className="mt-10 flex flex-row justify-center">
            <h1 className="font-lato font-semibold text-6xl text-cyan-700">
              Our Services
            </h1>
          </div>

          <div className="flex flex-wrap flex-row gap-10 justify-center mt-10 items-center place-items-center w-full ">
            <ServiceCard
              link="https://millracefootcare.simplybook.it/v2/#book/service/11/count/1/"
              title="New Patient Appointment"
              image={NewAppointment}
              price="65"
              duration="45-55"
              description="We are happy to meet you! During your initial consultation with us, we will review your medical history and conduct a comprehensive evaluation. This comprehensive service encompasses nail trimming and smoothing utilizing a podiatric standard drill, removal of calluses, treatment for fungal nails, verruca treatment utilizing Verrutop, management of ingrown toenails, removal of corns, athletes foot treatment, blister protection, and application of a nourishing cream or powder to complete the session."
            />
            <ServiceCard
              link="https://millracefootcare.simplybook.it/v2/#book/service/12/count/1/"
              title="Returning Patient Appointment"
              image={ReturningAppointment}
              price="49"
              duration="30-45"
              description="Welcome back! Our service includes the trimming and smoothing of nails utilizing a standard drill, removal of calluses, treatment of fungal nails, verruca treatment, management of ingrown toenails down to the midline, removal of seed corns, treatment of athletes foot, blister protection, and application of a nourishing cream to conclude the session."
            />
            <ServiceCard
              link="https://millracefootcare.simplybook.it/v2/#book/service/17/count/1/"
              title="Cryotherapy (freezing) of verrucae"
              image={Cryotheraphy}
              price="75"
              duration="30"
              description="Cryotherapy is executed with pinpoint accuracy via a localized spray of nitrous oxide. Verrucae, caused by the HPV virus, can be challenging to eliminate. Our cryotherapy technique employs temperatures of -89°C to freeze and eradicate the infected tissue where the virus resides. (Price for additional verruca treatment is subject to consultation)"
            />
            <ServiceCard
              link="https://millracefootcare.simplybook.it/v2/#book/service/15/count/1/"
              title="Toenail realignment /bracing"
              image={Toenail}
              price="45"
              duration="30"
              description="Nonsurgical cosmetic procedure that reshapes nails by inducing new pressure points. A supportive brace is worn for 4-6 weeks to secure the desired outcome."
            />
            <ServiceCard
              link="https://millracefootcare.simplybook.it/v2/#book/service/16/count/1/"
              title="Orthotic toe wedge/ spacer"
              image={Orthotic}
              price="20"
              duration="15"
              description="Effective relief from pain and friction by separating toes and preventing overlap. Our custom-made spacers are tailored to fit your foot for a personalized solution, unlike off-the-shelf products."
            />
            <ServiceCard
              title="Hyperkeratosis foot wrap"
              link="https://millracefootcare.simplybook.it/v2/#book/service/14/count/1/"
              image={FootWrap}
              price="60"
              duration="60"
              description="Thick calluses are removed through the use of a surgical scalpel and drill on a dry foot, followed by a 5-minute hydration therapy using warm foot bath and emollient oil with disposable liners for hygiene. Feet are then patted dry and treated with 20% urea cream, topped with double socks to be worn overnight."
            />
            <ServiceCard
              title="Lower Limb Aesthetics"
              image={LowerLimb}
              duration="60"
              price="180-250"
              description="Battling cellulite? PDO Mono thread therapy can tighten sagging skin, stimulate collagen production, and enhance lymphatic drainage. The clinic also offers micro-needling to improve the appearance of cellulite and reduce the appearance of common lower limb stretch marks."
            />
            <ServiceCard
              link="https://millracefootcare.simplybook.it/v2/#book/service/24/count/1/"
              title="Thai-vedic foot massage"
              image={ThaiMassage}
              duration="30"
              price="30"
              description="Revitalize your feet, lower legs, and knees with a combination of effleurage movements and Thai stick work targeting 23 reflex points. Experience improved circulation, enhanced lymphatic drainage, increased oxygenation, boosted immunity, reduced stiffness, improved flexibility, and deep relaxation with this treatment."
            />
          </div>

          <div className="mt-10 flex flex-row justify-center">
            <h2 className="font-lato font-semibold text-6xl text-cyan-700">
              Home Visits
            </h2>
          </div>

          <div className="mt-2 flex flex-row justify-center content-center place-content-center flex-wrap">
            <div className="w-full md:w-1/2 flex flex-col justify-center content-center place-content-center flex-wrap justify-items-center mb-2">
              <h2 className="font-roboto text-xl font-bold mb-2">
                Services Include
              </h2>
              <ul className="font-roboto list-disc">
                <li className="mt-2">Nail cutting and filing</li>
                <li className="mt-2">Corn removal</li>
                <li className="mt-2">Callus removal</li>
                <li className="mt-2">Verrucae treatment</li>
                <li className="mt-2">Fungal nail treatment</li>
              </ul>
            </div>
            <div className="w-full md:w-1/2 flex flex-col justify-center content-center place-content-center flex-wrap justify-items-center">
              <h2 className="font-roboto text-xl font-bold mb-2">
                Areas Covered:
              </h2>
              <ul className="font-roboto list-disc">
                <li className="mt-2">Battersea</li>
                <li className="mt-2">Chelsea</li>
                <li className="mt-2">Wandsworth</li>
                <li className="mt-2">Clapham Junction</li>
                <li className="mt-2">Clapham Common</li>
              </ul>
            </div>

            <div className="w-full md:w-1/2 flex flex-col justify-center mt-7 font-roboto gap-4 text-xl font-medium md:flex-row text-center md:gap-80">
              <h2 className="">New Patients £65</h2>
              <h2>Returning Patients £49</h2>
            </div>

            <div className="w-full mt-2 flex flex-row justify-center content-center place-content-center flex-wrap">
              <p className="w-10/12 font-roboto mt-2 text-center">
                To book an at home service please email your phone number and
                preferred email to millracefootcare@outlook.com (this service is
                available on Sundays only) you will be given a 3-hour time slot
                in which your practitioner will arrive.
              </p>
            </div>
          </div>

          <div className="mt-10 flex flex-row justify-center">
            <h2 className="font-lato font-semibold text-6xl text-cyan-700">
              Testimonials
            </h2>
          </div>

          <div className="mt-10 flex flex-row justify-center content-center place-content-center flex-wrap">
            <div className="w-full md:w-1/2 flex flex-col justify-center content-center place-content-center flex-wrap justify-items-center">
              <Ratings />
              <p className="w-full md:w-2/3 text-center font-light font-roboto italic">
                I had the best experience at this place by Helena, she really
                did an incredible job at cutting my nails. She made me feel calm
                and comfortable, I couldn’t ask for a better service. Definitely
                recommend it and would come back for more treatments.
              </p>
              <span className="text-center mt-3 font-roboto font-normal text-cyan-700">
                - Sheilan Hameed
              </span>
            </div>
            <div className="w-full md:w-1/2 flex flex-col justify-center place-content-center flex-wrap mt-10 md:mt-0">
              <Ratings />
              <p className="text-center w-2/3 font-light font-roboto italic">
                Had an ingrown toenail and was taken care by Helena, who was not
                only very professional and skillful but also very nice and
                caring, always asking if I was in pain and trying hard not to
                hurt me while doing the job. A good price for an amazing job and
                such a nice professional, can't recommend enough for anyone who
                needs their feet taken care of.
              </p>
              <span className="text-center mt-3 font-roboto font-normal text-cyan-700">
                - Alexandre Santana
              </span>
            </div>
          </div>

          <div className="mt-10 flex flex-row justify-center">
            <h3 className="font-lato font-semibold text-6xl text-cyan-700">
              Contact Us
            </h3>
          </div>

          <ContactForm />

          <Footer />
        </main>
      </div>
    </>
  );
}
