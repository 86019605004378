import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, RouterProvider } from "react-router-dom";

import App from "./App";
import Index from "./views/index.js";
import Contact from "./views/contact.js";
import ErrorPage from "./views/error-page.js";
import FAQ from "./views/faq.js";
import ScrollToTop from "./helpers/ScrollToTop.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <ScrollToTop />
    <App />
  </Router>
);
