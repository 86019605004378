import BookButton from "../components/BookButton";

export default function ServiceCard({
  title,
  duration,
  price,
  description,
  image,
  link,
}) {
  return (
    <div className="w-full max-w-sm pg-4 flex justify-center h-96 group perspective">
      <div className="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000">
        <div className="absolute w-full h-full backface-hidden">
          <img src={image} class="w-full h-full" alt="Service Image" />
          <div className="absolute w-full h-full bg-black top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-40"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-36 text-white font-lato border-black text-2xl font-medium w-2/3 ml-5 flex flex-col">
            <h1 className="mb-20">{title}</h1>
            <h2 className="mb-20"> {duration} Minutes</h2>
            <h3> £{price}</h3>
          </div>
        </div>
        <div className="absolute w-full h-full bg-sky-600 backface-hidden my-rotate-y-180 justify-center">
          <img src={image} class="w-full h-full" alt="Service Image" />
          <div className="absolute w-full h-full bg-black top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-70"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-36 text-white font-roboto font-light border-black w-full flex flex-col justify-center place-items-center place-content-center">
            <p className="mb-10">{description}</p>
            <BookButton link={link} type={title} />
          </div>
        </div>
      </div>
    </div>
  );
}
