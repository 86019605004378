import RFHP from "../assets/images/RFHP.jpg";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

export default function Footer() {
  return (
    <div className="bg-cyan-700 flex flex-col md:flex-row mt-10">
      <div className="h-full md:w-1/5 md:mt-10 md:mb-10 md:ml-10 mb-5 self-center">
        <img
          src={RFHP}
          alt="Registered Foot Health Practioner Member"
          className="rounded-full"
        />
      </div>
      <div className="h-full w-full md:w-1/5 md:mt-20 md:mb-10 md:ml-10 flex flex-col font-roboto text-white font-medium gap-6">
        <span className="text-center md:text-left">
          <Link to={"/"} className="hover:text-gray-400">
            Home
          </Link>
        </span>
        <span className="text-center md:text-left">
          <Link to={"/contact"} className="hover:text-gray-400">
            Contact Us
          </Link>
        </span>
        <span className="text-center md:text-left">
          <Link to={"/about"} className="hover:text-gray-400">
            About
          </Link>
        </span>
        <span className="text-center md:text-left">
          <Link to={"/faq"} className="hover:text-gray-400">
            FAQ
          </Link>
        </span>
      </div>
      <div className="h-full w-full md:w-1/5 md:mt-16 md:ml-10 mt-6 flex flex-col font-roboto text-white">
        <span className="font-medium text-xl mb-5 text-center md:text-left">
          Opening Times
        </span>
        <ul className="font-light text-center md:text-left">
          <li>Monday: Closed</li>
          <li>Tuesday: Closed</li>
          <li>Wednesday: 09:30-21:00</li>
          <li>Thursday: 09:30-21:00</li>
          <li>Friday: 09:30-21:00</li>
          <li>Saturday: 09:30-21:00</li>
          <li>Sunday: 09:30-21:00</li>
        </ul>
        <span className="mt-20 italic text-xs font-extralight invisible md:visible">
          Website built and designed by{" "}
          <a
            href="https://www.linkedin.com/in/callum-mcfarlane-4b0047213/"
            class="hover:text-gray-400"
          >
            Callum McFarlane
          </a>
        </span>
      </div>
      <div className="h-full w-full md:w-1/5 md:mt-16 md:mb-10 md:ml-10 flex flex-col font-roboto text-white">
        <span className="font-medium text-xl mb-5 text-center md:text-left">
          Address
        </span>
        <span className="font-light text-center md:text-left">
          521-525 Battersea Park Rd. London
        </span>
        <span className="mt-5 font-light text-center md:text-left">
          SW11 3BN
        </span>
        <div className="flex flex-col w-full mt-5">
          <div className="md:flex md:flex-row md:w-full md:mt-2 flex flex-col md:justify-start justify-center content-center place-items-center">
            <a
              href="https://www.facebook.com/MillraceFootcareBattersea"
              target="_blank"
              className="md:mr-4 hover:text-gray-400 mb-5 md:mb-0"
            >
              <FaFacebookF size="30" className="text-center" />
            </a>
            <a
              href="https://www.instagram.com/millracefootcare/"
              target="_blank"
              className="hover:text-gray-400"
            >
              <FaInstagram size="30" />
            </a>
          </div>
        </div>
      </div>
      <div className="h-96 mt-5 w-full md:w-1/5 md:mt-16 md:mb-10 md:ml-10 flex flex-col md:mr-4 md:h-72">
        <iframe
          className="w-full h-full"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.256038844675!2d-0.1686552836864623!3d51.471814421124506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760570d9439773%3A0x831e67ce48c05f51!2sMillrace%20foot%20care!5e0!3m2!1sen!2suk!4v1674405413974!5m2!1sen!2suk"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
