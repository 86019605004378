import React from "react";
import { Helmet } from "react-helmet";

import HeroImage from "../components/HeroImage.js";
import Footer from "../components/Footer.js";
import Accordion from "../components/Accordion.js";

const Content = () => {
  return (
    <h1 className="w-full text-center font-lato font-medium text-white text-6xl">
      Frequently Asked Questions
    </h1>
  );
};

export default function FAQ() {
  return (
    <>
      <Helmet>
        <title>
          Battersea Foot Care Specialist: Expert Treatment for Foot Pain in
          South London - Frequently Asked Questions
        </title>
        <meta
          name="description"
          content="Find answers to common questions about our foot care services on our FAQ page. Learn more about our treatments and appointment process. Contact us for any additional inquiries."
        />
      </Helmet>
      <div>
        <main className="justify-center items-center content-center w-full">
          <HeroImage background="bg-hero" content={<Content />} />
          <div className="w-full justify-center items-center content-center flex flex-row flex-wrap mt-10 gap-4">
            <Accordion />
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}
