import React from "react";
import { Helmet } from "react-helmet";

import HeroImage from "../components/HeroImage.js";
import ContactForm from "../components/ContactForm.js";
import Footer from "../components/Footer.js";

const Content = () => {
  return (
    <div className="flex flex-row content-start md:justify-center md:items-center flex-wrap w-full text-white pointer-events-none">
      <h1 className="w-full md:mt-0 mb-2 md:mb-12 text-center font-lato text-4xl md:text-6xl font-medium pointer-events-none">
        Where to find us
      </h1>
      <div className="flex flex-row w-full flex-wrap">
        <div className="w-full mt-5 md:w-2/5 flex md:flex-col flex-wrap text-center md:text-end md:mr-12 md:gap-4">
          <h2 className="w-full font-lato text-xl font-medium">
            Hours of Operation
          </h2>
          <span className="w-full font-roboto font-light text-sm">
            Wednesday - Sunday
          </span>
          <span className="w-full text-center md:text-right font-light font-roboto text-sm">
            9:30 am - 9pm
          </span>
          <span className="w-full md:w-full font-roboto font-light italic text-xs text-center mt-1 md:mt-0 md:text-right">
            *Out of ours appointments can be arranged through phone call
          </span>
        </div>
        <div className="w-full md:w-1/5 flex flex-row md:flex-col flex-wrap text-center">
          <h3 className="w-full font-lato md:text-3xl font-medium mb-5 text-xl">
            Address
          </h3>
          <span className="w-full font-roboto font-light">
            521 Battersea Park Rd, Inside Battersea Park Clinic, London
          </span>
          <span className="w-full font-roboto font-light">SW11 3BN</span>
          <h4 className="hidden md:block md:w-full md:mt-8 font-roboto">
            Email
          </h4>
          <a href="millracefootcare@outlook.com" className="hidden md:block">
            <span className="w-full font-roboto font-light italic underline pointer-events-auto">
              millracefootcare@outlook.com
            </span>
          </a>
        </div>
        <div className="hidden w-1/5 gap-4 h-full md:flex flex-col flex-wrap">
          <h5 className="w-full font-lato text-medium text-3xl">Phone</h5>
          <span className="w-full font-roboto font-light">07380-283548</span>
        </div>
        <div className="flex flex-row flex-wrap md:hidden w-full mt-5">
          <div className="w-1/2 flex flex-col flex-wrap">
            <h4 className="w-full font-roboto text-center text-sm">Email</h4>
            <a href="millracefootcare@outlook.com" className="">
              <span className="w-full font-roboto font-light italic underline pointer-events-auto text-xs">
                millracefootcare@outlook.com
              </span>
            </a>
          </div>
          <div className="w-1/2 flex flex-col flex-wrap">
            <h5 className="w-full font-roboto text-center text-sm">Phone</h5>
            <span className="w-full font-roboto font-light italic underline pointer-events-auto text-xs text-center mt-2">
              07380-283548
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>
          Battersea Foot Care Specialist: Contact Our Battersea Foot Care
          Specialist for Expert Treatment in London
        </title>
        <meta
          name="description"
          content="Get in touch with our foot care specialists in Battersea, London for expert treatment and support. Contact us today to schedule an appointment or ask any questions about our services."
        />
      </Helmet>
      <div>
        <main className="justify-center items-center content-center">
          <HeroImage background="bg-contact" content={<Content />} />
          <div className="mt-10 flex flex-row justify-center">
            <h3 className="font-lato font-semibold text-6xl text-cyan-700">
              Contact Us
            </h3>
          </div>
          <ContactForm />
        </main>

        <Footer />
      </div>
    </>
  );
}
