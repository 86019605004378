import { React, useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

export default function ContactForm() {
  const SERVICE_ID = "service_mk6m03s";
  const TEMPLATE_ID = "template_4w7rp53";
  const PUBLIC_KEY = "t4impZ6kvD-NeYnAD";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  // const HandleSubmit = (e) => {
  //   e.preventDefault();
  //   emailjs.send("service_mk6m03s", "template_4w7rp53", {
  //     from_name: firstName + " " + lastName,
  //     message: message,
  //     phone: phone,
  //     email: email,
  //   }.then(result) => {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Message Sent Successfully"
  //     })
  //   }, (error) => {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops, something went wrong",
  //       text: error.text,
  //     }))
  //   };
  // };

  const HandleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          from_name: firstName + " " + lastName,
          message: message,
          phone: phone,
          email: email,
        },
        PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Message Sent Successfully",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Ooops, something went wrong",
            text: error.text,
          });
        }
      );
  };

  return (
    <div className="mt-20 flex flex-col justify-center w-full flex-wrap">
      <form onSubmit={HandleSubmit}>
        <div className="flex flex-col md:flex-row w-full justify-center gap-x-48">
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            className="form-input rounded w-full md:w-4/12 font-roboto italic font-medium placeholder:text-black"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            className="form-input rounded w-full md:w-4/12 md:ml-10 mt-5 md:mt-0 font-roboto italic font-medium placeholder:text-black"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col md:flex-row w-full justify-center gap-x-48 mt-16">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email Address"
            className="form-input rounded w-full md:w-4/12 font-roboto italic font-medium placeholder:text-black"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone Number"
            className="form-input rounded w-full mt-5 md:w-4/12 md:ml-10 md:mt-0 font-roboto italic font-medium placeholder:text-black"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-row w-full justify-center mt-16 h-48">
          <textarea
            type="text"
            id="message"
            name="message"
            placeholder="Message"
            className="form-input rounded w-full md:w-8/12 h-full text-start font-roboto italic font-medium placeholder:text-black"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-row w-full justify-center">
          <button className="mt-8 px-12 py-3 bg-gradient-to-r from-cyan-700 to-teal-700 rounded-full p-3 w-60 hover:from-cyan-800 hover:to-teal-800 drop-shadow-lg text-white font-medium font-lato">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
