import React from "react";
import { LogBooking } from "../analytics";

export default function BookButton(props) {
  return (
    <button
      type="button"
      className="bg-gradient-to-r from-cyan-700 to-teal-700 rounded-full p-3 w-60 hover:from-cyan-800 hover:to-teal-800 drop-shadow-lg"
      onClick={() => LogBooking(props.type || "generic")}
    >
      <a
        href={
          props.link ||
          "https://millracefootcare.simplybook.it/v2/#book/count/1/"
        }
        target="_blank"
      >
        <span className="text-white text-2xl font-medium font-lato">
          Book Now
        </span>
      </a>
    </button>
  );
}

/* Book Now */
